const action = [
  // Product ###############################
  {
    title: 'PRODUCT',
    disableAction: true,
  },
  {
    title: 'Change product description to',
    value: 'change_product_description_to',
  },
  {
    title: 'Change product niche to',
    value: 'change_product_niche_to',
  },
  {
    title: 'Change product type',
    value: 'change_product_type_to',
  },
  // {
  //   title: '2 Add text to product description',
  //   value: 'add_text_to_product_description',
  // },
  // {
  //   title: '3 Add text to product title',
  //   value: 'add_text_to_product_title',
  // },
  // {
  //   title: '4 Remove text from product title',
  //   value: 'remove_text_from_product_title',
  // },
  // {
  //   title: '5 Replace text from to product title',
  //   value: 'replace_text_from_product_title',
  // },
  // {
  //   title: '6 Change product vendor to',
  //   value: 'change_product_vendor_to',
  // },
  {
    title: 'Remove tags',
    value: 'remove_tags',
  },
  {
    title: 'Add tags',
    value: 'add_tags',
  },
  {
    title: 'Change variant description to',
    value: 'change_variant_description_to',
  },
  {
    title: 'Add custom options',
    value: 'add_custom_options',
  },
  // {
  //   title: 'Replace custom options',
  //   value: 'replace_custom_options',
  // },
  // {
  //   title: '11 Change variants option value',
  //   value: 'change_variant_option_value',
  // },
  // {
  //   title: '12 Show on channels',
  //   value: 'show_on_a_channel',
  // },
  // {
  //   title: '13 Hide on channels',
  //   value: 'hide_on_a_channel',
  // },
  // {
  //   title: '14 Add to collection',
  //   value: 'add_to_collection',
  // },
  // {
  //   title: '15 Remove from collection',
  //   value: 'remove_from_collection',
  // },
  // {
  //   title: '16 Change inventory quantity to',
  //   value: 'change_inventory_quantity_to',
  // },
  // {
  //   title: '17 Find and replace text in SKU',
  //   value: 'find_and_replace_sku',
  // },
  // {
  //   title: '18 Add text to SKU',
  //   value: 'add_text_to_sku',
  // },
  // {
  //   title: '19 Update product weight',
  //   value: 'update_product_weight',
  // },
  // {
  //   title: '20 Update product weight by percentage',
  //   value: 'update_product_weight_by_percentage',
  // },
  // Price ###############################
  {
    title: 'PRICE',
    disableAction: true,
  },
  {
    title: 'Change price to',
    value: 'change_price_to',
  },
  {
    title: 'Increase price by amount',
    value: 'increase_price_by_amount',
  },
  {
    title: 'Decrease price by amount',
    value: 'decrease_price_by_amount',
  },
  {
    title: 'Increase price by percentage',
    value: 'increase_price_by_percentage',
  },
  {
    title: 'Decrease price by percentage',
    value: 'decrease_price_by_percentage',
  },
  // {
  //   title: 'Round/ Beautify price',
  //   value: 'round_beautify_price',
  // },
  // Compare ###############################
  {
    title: 'COMPARE AT PRICE',
    disableAction: true,
  },
  {
    title: 'Change compare at price to',
    value: 'change_compare_at_price_to',
  },
  {
    title: 'Change compare at price base on price',
    value: 'set_compare_at_price_based_on_price',
  },
  {
    title: 'Increase compare at price by',
    value: 'increase_compare_at_price_by_amount',
  },
  {
    title: 'Decrease compare at price by',
    value: 'decrease_compare_at_price_by_amount',
  },
  {
    title: 'Increase compare at price by percentage',
    value: 'increase_compare_at_price_by_percentage',
  },
  {
    title: 'Decrease compare at price by percentage',
    value: 'decrease_compare_at_price_by_percentage',
  },
  // {
  //   title: '32 Round/ Beautify compare-at-price',
  //   value: 'round_beautify_compare_at_price',
  // },
  // {
  //   title: '33 Update compare-at-price based on price',
  //   value: 'set_compare_at_price_based_on_price',
  // },
];
export default {
  action,
};

import storeRequest from './request/storeRequest';
import STORAGE_NAME from '@/const/storage';

let storeId = localStorage.getItem(STORAGE_NAME.STORE_ID);
storeRequest.setStoreId(storeId);
let path = '/stores/members/';

function get(params) {
  return storeRequest.get(path, { params: params });
}
function getById(id) {
  return storeRequest.get(path + id);
}
function getOrderById(idMember) {
  return storeRequest.get(path + idMember + `/orders`);
}
function removeProductImportList(data) {
  return storeRequest.post(path + '/remove-all?' + data);
}
export const memberService = {
  get,
  removeProductImportList,
  getById,
  getOrderById,
};

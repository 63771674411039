import storeRequest from './request/storeRequest';
import STORAGE_NAME from '@/const/storage';

let storeId = localStorage.getItem(STORAGE_NAME.STORE_ID);
storeRequest.setStoreId(storeId);

function get(params) {
  return storeRequest.get('/product-mass-updates', { params: { ...params } });
}
function getProduct(params) {
  return storeRequest.post('/product-mass-updates/preview', { productMassUpdate: params, noCount: 1 });
}
function getCount(params) {
  return storeRequest.post('/product-mass-updates/preview/count', { productMassUpdate: params });
}
function getById(id) {
  return storeRequest.get('/product-mass-updates' + id);
}
function post(params) {
  return storeRequest.post('/product-mass-updates', { productMassUpdate: params });
}
function put(params) {
  return storeRequest.put('/product-mass-updates', { productMassUpdate: params });
}
function deleteById(id) {
  return storeRequest.delete('/product-mass-updates' + id);
}

export const bulkUpdateApi = {
  get,
  getProduct,
  getCount,
  getById,
  post,
  put,
  deleteById,
};

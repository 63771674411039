import axios from 'axios';
import utilities from '@/helpers/utilities';
import FORMAT_APP from '@/const/format';
import STORAGE_NAME from '@/const/storage';
import Request from '@/apis/index';
import { productApi } from '@/apis/product';
import { abcheckoutApi } from '@/apis/abandons';
import { personalizesApi } from '@/apis/personalizes';
import { pageService } from '@/apis/page.s';
import { convertIdsToArrays } from '@/helpers/convertData';
import { menuFilterApi } from '@/apis/menufilter';

import { memberService } from '@/apis/member';
import { collectionApi } from '@/apis/collection';
import { variantApi } from '@/apis/variants';
import { bulkUpdateApi } from '@/apis/bulkupdate';
import { sizechartApi } from '@/apis/sizecharts';
import { customerService } from '@/apis/customer';
import { discountService } from '@/apis/discount';
import { orderApi } from '@/apis/order';
import { domainService } from '@/apis/domain.s';
import { offerApi } from '@/apis/offers';
import { shortlinkApi } from '@/apis/shortlink';
import actionBulkUpdates from '../views/website/bulkupdates/actionBulkUpdates';
import event from '@/plugins/event-bus';
import { mapGetters, mapState } from 'vuex';
import { printHubApi } from '@/apis/printHub';
import currency from '@/helpers/currency';
export default {
  data() {
    return {
      currency,
      // đổi value thành title ở Bulk update
      listChangeValueToTitle: actionBulkUpdates.action,
      // Resource type
      resourceType: null,
      isMount: false,
      updateRouteName: null,
      paramsCount: null,
      // State
      lastExecuteRequest: null,
      isLoading: true,
      errors: [],
      //tab header
      currentTab: 0,
      // Executor
      executor: null,

      // Data
      limit: FORMAT_APP.LIMIT_PAGE,
      page: 1,
      firstPage: null,
      sort: '-created_at',
      filterData: [],
      params: {},

      // Search
      searchableField: '',
      searchString: '',
      keyUpAt: null,
      lengthPage: 1,
      document: null,
      //route
      clear: false,
      countt: '',
      // các data để display tại màn index bulk
      itemsVariant2: [
        {
          title: 'contains',
          value: 'contain',
          customDisabledFilter: false,
        },
        {
          title: 'does not contains',
          value: 'notContain',
          customDisabledFilter: false,
        },
        {
          title: 'starts with',
          value: 'startWith',
          customDisabledFilter: false,
        },
        {
          title: 'ends with',
          value: 'endWith',
          customDisabledFilter: false,
        },
        {
          title: 'is equal to',
          value: 'equal',
          customDisabledVariant: false,
        },
        {
          title: 'is not equal to',
          value: 'notEqual',
          customDisabledVariant: false,
        },
        {
          title: 'is greater than',
          value: 'greaterThan',
          customDisabledVariant: false,
        },
        {
          title: 'is less than',
          value: 'lessThan',
          customDisabledVariant: false,
        },
      ],
      itemsVariant1: [
        {
          title: 'Variant title',
          value: 'variant.title',
          compare: ['equal', 'notEqual', 'startWith', 'endWith', 'contain', 'notContain'],
        },
        {
          title: 'Variant price',
          value: 'variant.price',
          compare: ['equal', 'notEqual', 'greaterThan', 'lessThan'],
        },
        {
          title: 'Variant compare-at-price',
          value: 'variant.compareAtPrice',
          compare: ['equal', 'notEqual', 'greaterThan', 'lessThan'],
        },
        {
          title: 'Variant weight',
          value: 'variant.weight',
          compare: ['equal', 'notEqual', 'greaterThan', 'lessThan'],
        },
      ],
      itemsFilter2: [
        {
          title: 'contains',
          value: 'contain',
          customDisabledFilter: false,
        },
        {
          title: `doesn't contains`,
          value: 'notContain',
          customDisabledFilter: false,
        },
        {
          title: 'is equal to',
          value: 'equal',
          customDisabledFilter: false,
        },
        {
          title: 'is not equal to',
          value: 'notEqual',
          customDisabledFilter: false,
        },
        {
          title: 'is greater than',
          value: 'greaterThan',
          customDisabledFilter: false,
        },
        {
          title: 'is less than',
          value: 'lessThan',
          customDisabledFilter: false,
        },
        {
          title: 'starts with',
          value: 'startWith',
          customDisabledFilter: false,
        },
        {
          title: 'ends with',
          value: 'endWith',
          customDisabledFilter: false,
        },
      ],
      itemsFilter1: [
        {
          title: 'Product title',
          value: 'title',
          compare: ['equal', 'notEqual', 'startWith', 'endWith', 'contain', 'notContain'],
        },
        {
          title: 'Product type',
          value: 'productType',
          compare: ['equal', 'notEqual', 'startWith', 'endWith', 'contain', 'notContain'],
        },
        {
          title: 'Product tag',
          value: 'tags',
          compare: ['equal', 'contain', 'notContain'],
        },
        {
          title: 'Product vendor',
          value: 'vendor',
          compare: ['equal', 'notEqual', 'startWith', 'endWith', 'contain', 'notContain'],
        },
        {
          title: 'Product price',
          value: 'variant.price',
          compare: ['equal', 'notEqual', 'greaterThan', 'lessThan'],
        },
        {
          title: 'Compare at price',
          value: 'variant.compareAtPrice',
          compare: ['equal', 'notEqual', 'greaterThan', 'lessThan'],
        },
        {
          title: 'Weight',
          value: 'variant.weight',
          compare: ['equal', 'notEqual', 'greaterThan', 'lessThan'],
        },
        {
          title: 'Inventory Stock',
          value: 'inventoryQuantity',
          compare: ['equal', 'notEqual', 'greaterThan', 'lessThan'],
        },
        {
          title: 'Variant title',
          value: 'variant.title',
          compare: ['equal', 'notEqual', 'startWith', 'endWith', 'contain', 'notContain'],
        },
        {
          title: 'Collection',
          value: 'collections',
          compare: ['equal', 'notEqual'],
        },
      ],
    };
  },
  created() {
    // Set current page
    this.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1;
    event.$on('countProduct', async () => {
      // this.countt = 0;
      // console.log(`this.paramsCount`, this.paramsCount);
      let count = await productApi.count(this.paramsCount);
      this.countt = count.data.count;
    });
  },

  async mounted() {
    // this.isMount = false;
    await this.execute();
    // this.isMount = true;
  },
  methods: {
    /**
     * Executor
     */
    // là phần prefix khi bulkupdate giá theo % hay theo USD trong dialog ( và ngoài màn index) hiện lên, sau khi ấn Preview Bulk Update
    displayPrefix(a) {
      let result;
      if (a && a.command && a.command != '') {
        if (a.command.includes('percentage')) {
          result = '%';
        }
        if (a.command.includes('amount')) {
          result = currency.currencyCode();
        }
        if (a.command.includes('price_to')) {
          result = currency.currencyCode();
        }
      }
      return result;
    },
    getNameAddCustomAction(item) {
      let arr = [];
      item.actionOption.forEach(i => {
        if (i.name != '') {
          arr.push(i.name);
        }
      });
      return arr.join(', ');
    },
    // check xem value là string hay array ( với add, remove tag) để display
    checkTypeOfValue(a) {
      let result;
      if (Array.isArray(a)) {
        result = a.join(', ');
      }
      if (typeof a == 'string') {
        result = a;
      }
      return result;
    },
    changeConditionTitle(a) {
      let result;
      if (a == 'equals') {
        result = 'is equal to';
      }
      if (a == 'contains') {
        result = 'contains';
      }
      if (a == 'not_contains') {
        result = `doesn't contains`;
      }
      return result;
    },
    // đổi value của action bên ngoài màn index
    changeActionValue(a) {
      let result = a.value;
      // if (
      //   a &&
      //   a.command &&
      //   (a.command == 'change_product_description_to' || a.command == 'change_variant_description_to')
      // ) {
      //   result = a.value;
      // }
      // if (a && a.command && a.command.includes('price')) {
      //   result = a.value;
      // }
      // if (
      //   (a && a.command && a.command == 'remove_tags') ||
      //   (a && a.command && a.command == 'add_tags') ||
      //   (a && a.command && a.command == 'change_product_niche_to')
      // ) {
      //   result = a.value;
      // }
      return result;
    },
    // đổi tên của các action và filter ở dialog khi click Preview Bulk Update
    changeActionName(a) {
      let result;
      this.listChangeValueToTitle.forEach(item => {
        if (item.value == a) {
          result = item.title;
        }
      });
      return result;
    },
    changeColumnName(a) {
      let b = this.itemsFilter1.find(i => i.value == a);
      let c = b?.title;
      return c;
    },
    changeColumnRelation(a) {
      let b = this.itemsFilter2.find(i => i.value == a);
      let c = b?.title;
      return c;
    },
    changeColumnVariantName(a) {
      let b = this.itemsVariant1.find(i => i.value == a);
      let c = b?.title;
      // console.log(c);
      return c;
    },
    changeColumnVariantRelation(a) {
      let b = this.itemsVariant2.find(i => i.value == a);
      let c = b?.title;
      // console.log(c);
      return c;
    },
    changeActionTitle(a) {
      let arr = [];
      a.forEach(i => {
        i.name;
        arr.push(i.name);
      });
      // console.log(arr);
      return arr.join();
    },
    execute() {
      if (this.executor) {
        clearTimeout(this.executor);
        this.executor = null;
      }

      this.executor = setTimeout(() => {
        this.getResources();
      }, 300);
    },
    async getResources() {
      // console.log(this.$route);
      // Reset state
      this.isLoading = true;
      this.document = null;
      this.errors = [];
      try {
        // Get page 6014ca86344f35586ec2abc7 6009422b44f99563f83d980d
        let params = {
          page: this.page,
          limit: this.limit,
          // search: this.searchString,
        };
        if (this.searchString) {
          params.search = this.searchString;
        }
        if (this.$route.query._query) {
          let queries = JSON.parse(this.$route.query._query);
          // console.log(queries.value.code);
          queries.forEach(q => {
            if (q.compare) {
              params[q.field] = q.value;
              params[q.fieldCompare] = q.compare;
            } else {
              params[q.field] = Array.isArray(q.value) ? q.value.join(',') : q.value;
            }
          });
        }
        // query ở 3 thanh select trong abandoned
        if (this.$route.query.emailStatus) {
          params.emailStatus = this.$route.query.emailStatus;
        }
        if (this.$route.query.smsStatus) {
          params.smsStatus = this.$route.query.smsStatus;
        }
        if (this.$route.query.recoveryStatus) {
          params.recoveryStatus = this.$route.query.recoveryStatus;
        }
        // query api ở 2 thanh select trong order
        if (this.$route.query.paymentStatus) {
          params.financialStatus = this.$route.query.paymentStatus;
        }
        if (this.$route.query.fulfillmentStatus) {
          params.fulfillmentStatus = this.$route.query.fulfillmentStatus;
        }
        // query time
        if (this.$route.query.startDate) {
          params.startDate = this.$route.query.startDate;
        }
        if (this.$route.query.endDate) {
          params.endDate = this.$route.query.endDate;
        }
        let storeDomain = localStorage.getItem(STORAGE_NAME.STORE_DOMAIN);
        if (this.resourceType === 'offer') {
          // console.log(this.$route.query);
          if (this.$route.query && this.$route.query.tab) {
            // console.log(this.$route.query.tab);
            params.offerType = this.$route.query.tab;
          } else if (this.$route.query.tab == null) {
            // console.log(this.$route.query.tab);
            if (this.offerType) {
              params.offerType = this.offerType;
            }
          }
          //conver dataOffer
          let res = await offerApi.get(params);
          this.document = res.data.offers.map(item => {
            return Promise.all([
              convertIdsToArrays('collection', item.collectionRecommendIds),
              convertIdsToArrays('collection', item.collectionTargetIds),
              convertIdsToArrays('product', item.productRecommendIds),
              convertIdsToArrays('product', item.productTargetIds),
              convertIdsToArrays('product', item.handpickedProductIds),
              convertIdsToArrays('collection', item.handpickedCollectionIds),
            ]).then(datas => {
              let dataConvert = item;
              return {
                ...dataConvert,
                activated: dataConvert.activated ? 'active' : 'deactive',
                seeMore: true,
                collectionRecommendIds: datas[0],
                collectionTargetIds: datas[1],
                productRecommendIds: datas[2],
                productTargetIds: datas[3],
                handpickedProductIds: datas[4],
                handpickedCollectionIds: datas[5],
              };
            });
          });
          Promise.all(this.document).then(datas => {
            this.document = datas;
          });
          let count = await offerApi.count(params);
          this.lengthPage = Math.ceil(count.data.count / this.limit);
        }
        if (this.resourceType === 'personalizes') {
          // là gọi api ở tab All products
          if (
            !this.$route.query ||
            (this.$route.query && (!this.$route.query.tab || this.$route.query.tab === 'product'))
          ) {
            params.isPersonalize = false;

            // let count = await productApi.count(params);
            // this.lengthPage = Math.ceil(count.data.count / this.limit);
            let res = await productApi.get(params);
            this.document = res.data.products;
            // console.log('1', this.document);
          }
          // là gọi api ở tab Personalized
          else {
            // cái 'personalize:desc là xếp theo thứ tự trước sau
            // còn thay params.sort=  '_id:desc' thì là thứ tự theo id
            // params.isPersonalize = true;
            params.sort = 'personalize:desc';
            if (this.$route.query.tab === 'text') {
              params.personalizeType = `text`;
            }
            if (this.$route.query.tab === 'preview') {
              params.personalizeType = `preview`;
            }
            this.lengthPage = 10000;
            let res = await personalizesApi.get(params);
            this.document = res.data.map(item => {
              const xId = item.productId._id;
              delete item.productId._id;
              return {
                xId,
                ...item,
                ...item.productId,
              };
            });
          }
        }
        if (this.resourceType === 'products') {
          if (this.$route.query && this.$route.query.tab) {
            let tab = this.$route.query.tab;
            params.published = this.$route.query.tab == 'published' ? true : false;
          }

          this.paramsCount = { ...params };
          let res = await productApi.get(params);
          this.document = res.data.products;
          // console.log(this.document);
        }
        if (this.resourceType === 'variants') {
          let count = await variantApi.count(params);
          // console.log(count);
          this.lengthPage = Math.ceil(count.data.count / this.limit);
          let res = await variantApi.get(params);
          this.document = res.data.variants;
          // console.log(this.document);
        }
        if (this.resourceType === 'bulkupdates') {
          // let count = await variantApi.count(params);
          // this.lengthPage = Math.ceil(count.data.count / this.limit);
          // let res = await variantApi.get(params);
          let res = await bulkUpdateApi.get(params);
          this.document = res.data.productMassUpdates;
          console.log(res);
        }
        // console.log(this.resourceType, 'members');
        if (this.resourceType === 'members') {
          // let count = await variantApi.count(params);
          // this.lengthPage = Math.ceil(count.data.count / this.limit);
          // let res = await variantApi.get(params);
          let res = await memberService.get(params);
          this.document = res.data.members;

          // console.log(res, 'members');
        }
        if (this.resourceType === 'sizechart') {
          let res = await sizechartApi.get(params);
          this.document = res.data;
          // console.log(this.$route);
          // console.log(this.document);
          this.lengthPage = Math.ceil(this.document.length / this.limit);
        }
        // if (this.resourceType === 'dns') {
        //   let res = await orderApi.get(params);
        //   this.document = res.data;
        // }
        if (this.resourceType === 'collections') {
          if (this.$route.query && this.$route.query.tab) {
            let tab = this.$route.query.tab;
            params.collectionType = tab;
          }
          let res = await collectionApi.get(params);
          this.document = res.data.collections;
          let count = await collectionApi.count(params);
          this.countt = count.data.count;
          this.lengthPage = Math.ceil(count.data.count / this.limit);
        }
        if (this.resourceType === 'customer') {
          let res = await customerService.list(params);
          this.document = res.data.customers;
          // console.log(this.document);
          this.lengthPage = Math.ceil(this.document.length / this.limit);
          // console.log(this.document.length);
          // console.log(this.limit);
          // console.log(this.lengthPage);
        }
        if (this.resourceType === 'menufilter') {
          this.isLoading = true;
          this.isMount = false;
          // console.log('get document', this.document);

          let res = await menuFilterApi.getFilter(params);
          this.document = res.data.sort((a, b) => (a.position < b.position ? -1 : 1));
          this.isMount = true;
        }
        if (this.resourceType === 'campaign') {
          let res = await printHubApi.getCampaign(params);
          this.lengthPage = Math.ceil((res.data.paging.total || 0) / parseInt(res.data.paging.limit));
          this.document = res.data.campaigns;
          // this.isMount = true;
        }
        if (this.resourceType === 'discount') {
          if (this.$route.query && this.$route.query.tab) {
            let tab = this.$route.query.tab;
            params.tab = tab;
          }
          if (this.$route.name == 'website.discount.code') {
            params.discount_type = 'manual';
          } else {
            params.discount_type = 'automatic';
          }
          // this.$delete(params, 'search');
          let discountData = await discountService.list(params);
          this.document = discountData.data.discount;
          let count = discountData.data.count;
          this.lengthPage = Math.ceil(count / this.limit);
          // console.log(discountData);
        }
        if (this.resourceType === 'orders') {
          let caculatorTotalPrice = items => {
            let sum = 0;
            items.forEach(element => {
              sum = sum + element.price;
            });
            return sum;
          };

          let res = await orderApi.get(params);
          // let data = await orderApi.getCountry(params);
          // console.log(res);
          let count = await orderApi.getCount(params);
          this.lengthPage = Math.ceil(count.data / this.limit);
          this.document = res.data.map(item => {
            item.financialStatus = item.financialStatus.replace('_', ' ');
            item.financialStatus = item.financialStatus.charAt(0).toUpperCase() + item.financialStatus.slice(1);
            return {
              ...item,
              total: item.grandTotal,
              name: item.orderName,
            };
          });
          // console.log(this.document);
        }
        if (this.resourceType === 'abcheckout') {
          let caculatorTotalPrice = items => {
            let sum = 0;
            items.forEach(element => {
              sum = sum + element.price;
            });
            return sum;
          };
          let count = await abcheckoutApi.getCount(params);
          this.lengthPage = Math.ceil(count.data / this.limit);
          let res = await abcheckoutApi.getAbcheckouts(params);
          // console.log(count);
          // console.log(parseInt(res.data[0].cart.paidInfo.grand_total));
          this.document = res.data.map(item => {
            return {
              ...item,
              total: parseFloat(item.cart?.paidInfo?.grand_total || 0),
              // name: `#${storeDomain.toUpperCase().split('.')[0]}-00${item.orderId}`,
              name: `#${item.cart.id.slice(0, 8)}`,
            };
          });
          // console.log(this.document);
        }
        if (this.resourceType === 'pages') {
          // const api = new Request();
          // let storeId = localStorage.getItem(STORAGE_NAME.STORE_ID);
          // api.setStoreId(storeId);
          // let token = localStorage.getItem('access-token');
          // api.setToken(token);
          // api.setBaseURL('https://' + storeDomain + '/api/');
          // this.document = (await api.get(`/stores/onlinestore/${this.resourceType}`)).data[this.resourceType];
          let res = await pageService.list(params);
          this.document = res.data.pages;
          // console.log(res);
        }
        if (this.resourceType === 'product-feed') {
          let count = await productApi.getCountFeed(params);
          this.lengthPage = Math.ceil(count.data.count / this.limit);
          let res = await productApi.getProductFeed(params);
          this.document = res.data.productFeeds;
        }
        if (this.resourceType === 'shortlinks') {
          let count = await shortlinkApi.count();
          this.lengthPage = Math.ceil(count.data / this.limit);
          let res = await shortlinkApi.list(params);
          this.document = res.data;
        }
        if (this.resourceType === 'catalog') {
          let tab = '';
          let res = await printHubApi.get({ page: 0, limit: 1000 });
          this.$store.commit('setCatalog', res.data.mockups || []);
          this.document = res.data.mockups;
          // if (this.$route.query && this.$route.query.tab) {
          //   tab = this.$route.query.tab;
          // }
          // let string = 'catalog-' + tab;
          // let check = this.apiCalled.findIndex(item => item.api === string);
          // if (check === -1) {
          //   this.$store.commit('setListApiCalled', { api: string, change: false });
          //   if (tab === 'over print') {
          //     let res = await printHubApi.get();
          //     this.$store.commit('setCatalog', res.data.mockups || []);
          //     console.log('data', res);
          //     this.document = res.data.mockups;
          //   }
          // } else {
          //   if (this.apiCalled[check].change) {
          //     if (tab === 'over print') {
          //       console.log(tab);
          //       let res = await printHubApi.get();
          //       this.$store.commit('setCatalog', res.data.mockups || []);
          //       this.document = res.data.mockups;
          //     }
          //   } else {
          //     if (tab === 'over print') {
          //       console.log(tab);
          //       this.document = this.catalog;
          //     }
          //   }
          // }
        }
        if (this.resourceType === 'artwork') {
          let tab = '';
          if (this.$route.query && this.$route.query.tab) {
            tab = this.$route.query.tab;
          }
          let string = 'artwork-' + tab;
          let check = this.apiCalled.findIndex(item => item.api === string);
          let res = await printHubApi.getArtwork({ page: this.page - 1, limit: this.limit, deleted: false });
          this.$store.commit('setArtwork', res.data.artworks);
          this.document = res.data.artworks;
          // if (check === -1) {
          //   this.$store.commit('setListApiCalled', { api: string, change: false });
          //   if (tab === 'all') {
          //     let res = await printHubApi.getArtwork({ page: this.page - 1, limit: this.limit, deleted: false });
          //     this.$store.commit('setArtwork', res.data.artworks.filter(i => i.deleted === false) || []);
          //     this.document = res.data.artworks.filter(i => i.deleted === false);
          //     console.log(
          //       'artwork',
          //       res.data.artworks.filter(i => i.deleted === false),
          //     );
          //   }
          //   if (tab === 'personalizable') {
          //     this.document = [];
          //   }
          //   if (tab === 'static') {
          //     this.document = [];
          //   }
          // } else {
          //   if (this.apiCalled[check].change) {
          //     if (tab === 'all') {
          //       let res = await printHubApi.getArtwork({ page: this.page - 1, limit: this.limit });
          //       this.$store.commit('setArtwork', res.data.artwork || []);
          //       this.document = res.data.artwork;
          //     }
          //   } else {
          //     this.document = this.artwork;
          //   }
          // }
        }
        // Loading state off
        this.isLoading = false;
        this.init = true;
      } catch (e) {
        console.log('e', e);
        this.errors = utilities.handleErrorResponse(e);
        this.isLoading = false;
      }
    },
    /**
     * Set filter data
     */
    setFilterData(filterData) {
      // console.log(filterData, 'filterData');
      this.filterData = filterData;
    },

    /**
     * Set sort
     */
    setSort(sort) {
      this.sort = sort;
    },

    /**
     * Set limit
     */
    setLimit(limit) {
      // console.log(limit);
      this.limit = limit;
      // this.page = 1;
    },

    /**
     * Get update router data
     */
    getUpdateRouterData(id, queries) {
      this.$router.push({
        name: this.updateRouteName,
        params: {
          id,
        },
        query: queries,
      });
      console.log(id);
    },
  },
  computed: {
    ...mapGetters(['apiCalled', 'catalog', 'artwork']),
  },
  watch: {
    $route: function() {
      this.execute();
      if (!this.$route.name.includes('website.orders')) {
        // console.log('AAAAAAAAAAAAA');
        this.clear = true;
        // console.log(this.clear);
      }
      if (this.$route.name.includes('website.orders')) {
        this.clear = false;
        // console.log(this.clear);
      }
      // Set current page
      this.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1;
      if (!this.page) {
        this.firstPage = '';
      }
    },

    page: function() {},

    limit: function() {
      this.isLoading = true;
      // để khi change limit thì luôn trở về pagination 1
      this.page = 1;
    },

    sort: function() {
      this.isLoading = true;
    },

    filterData: function() {
      this.isLoading = true;
    },

    searchString: function(searchString) {
      this.keyUpAt = new Date().getTime();
      setTimeout(() => {
        let now = new Date().getTime();
        if (this.keyUpAt && now > this.keyUpAt + 800) {
          this.page = 1;
          this.keyUpAt = null;

          // Remove other filters & sorting
          this.sort = null;
          // this.filterData = [];

          this.getResources();
        }
      }, 1000);
    },
  },
};
